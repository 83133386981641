// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dq_Bx";
export var servicesToolkitSection__card = "dq_By";
export var servicesToolkitSection__icons = "dq_Bz";
export var servicesToolkitSection__serviceToolkitIcon1 = "dq_BC";
export var servicesToolkitSection__serviceToolkitIcon10 = "dq_BN";
export var servicesToolkitSection__serviceToolkitIcon11 = "dq_BP";
export var servicesToolkitSection__serviceToolkitIcon12 = "dq_BQ";
export var servicesToolkitSection__serviceToolkitIcon13 = "dq_BR";
export var servicesToolkitSection__serviceToolkitIcon14 = "dq_BS";
export var servicesToolkitSection__serviceToolkitIcon15 = "dq_BT";
export var servicesToolkitSection__serviceToolkitIcon16 = "dq_BV";
export var servicesToolkitSection__serviceToolkitIcon17 = "dq_BW";
export var servicesToolkitSection__serviceToolkitIcon18 = "dq_BX";
export var servicesToolkitSection__serviceToolkitIcon19 = "dq_BY";
export var servicesToolkitSection__serviceToolkitIcon2 = "dq_BD";
export var servicesToolkitSection__serviceToolkitIcon20 = "dq_BZ";
export var servicesToolkitSection__serviceToolkitIcon3 = "dq_BF";
export var servicesToolkitSection__serviceToolkitIcon4 = "dq_BG";
export var servicesToolkitSection__serviceToolkitIcon5 = "dq_BH";
export var servicesToolkitSection__serviceToolkitIcon6 = "dq_BJ";
export var servicesToolkitSection__serviceToolkitIcon7 = "dq_BK";
export var servicesToolkitSection__serviceToolkitIcon8 = "dq_BL";
export var servicesToolkitSection__serviceToolkitIcon9 = "dq_BM";
export var servicesToolkitSection__sprite = "dq_BB";
export var servicesToolkitSection__title = "dq_B0";