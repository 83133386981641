// extracted by mini-css-extract-plugin
export var reactAppDevBusinessSection = "v_c1";
export var reactAppDevCardBorderIconSection = "v_c0";
export var reactAppDevCaseSection = "v_c7";
export var reactAppDevClientsSection = "v_c9";
export var reactAppDevExpertiseSection = "v_c8";
export var reactAppDevIndustriesSection = "v_c2";
export var reactAppDevPreferencesSection = "v_c5";
export var reactAppDevProcessSection = "v_c3";
export var reactAppDevProjLogoSection = "v_c6";
export var reactAppDevServicesIconSection = "v_cZ";
export var reactAppDevTechStackSection = "v_c4";